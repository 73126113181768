@import "../../styles/variables";

.list-item {
  &.form-control {
    background-color: $white;
    border-bottom: 1px solid $gray10;
    border-radius: $spacer2x;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    gap: $spacer2x;
    grid-template-columns: auto;
    position: relative;
    width: 100%;

    // Ensure all children inherit cursor pointer.
    * {
      cursor: inherit;
    }

    &.flat-bottom {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    button.action-button {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    .action-icon {
      border: solid $gray80;
      border-radius: 0px;
      border-width: 0 2px 2px 0;
      padding: $spacer1x;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    .icon-lineup {
      display: flex;
      gap: $spacer2x;
    }

    .start-icon {
      height: auto;
      width: $spacer8x;
    }

    .label-wrap {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: $spacer4x;
      padding: $spacer4x;
      padding-bottom: calc(
        $spacer4x - 2px
      ); // Bottom is less to vertically center.
      position: relative;

      .content-horizontal,
      .content-vertical {
        display: flex;
        width: 100%;
      }

      .content-horizontal {
        align-items: center;
        flex-direction: row;
        gap: $spacer4x;
      }

      .content-vertical {
        align-items: flex-start;
        flex-direction: column;
        gap: $spacer3x;

        .text-container {
          gap: $spacer2x;
        }

        .description-text {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          gap: $spacer2x;
        }

        .title-text {
          &.primary {
            margin-bottom: 0;
          }
        }
      }
    }

    .description-text {
      color: $gray100;
      font-size: calc($fontSize - 2px);
      line-height: calc($fontSize + $spacer1x);
      max-width: max-content;
    }

    .label-text {
      color: $black;
      font-size: $fontSize;
      min-width: 100px;
      padding-bottom: $spacer2x;
      padding-top: $spacer2x;
    }

    .title-text {
      color: $black;
      font-size: $fontSize;
      padding-bottom: $spacer2x;
      padding-top: $spacer2x;

      &.large {
        font-size: calc($fontSize + 4px);
        line-height: calc($fontSize + 6px);
      }

      &.primary {
        color: $black;
        font-size: calc($fontSize + 4px);
        font-style: normal;
        font-weight: 700;
        line-height: calc($fontSize + 6px);
        padding: 0 0 2px 0;
      }
    }

    .text-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: left;
      text-align: left;
    }

    .toggle-switch {
      margin: 0 !important;
    }

    &.default,
    &.warning,
    &.error {
      .description-text {
        align-items: center;
        display: flex;
        width: max-content;
      }

      .icon {
        height: $spacer6x;
        margin-right: $spacer1x;
      }

      .text-container {
        flex-direction: column;
        gap: calc($spacer1x / 2);
      }
    }

    &.default {
      .description-text {
        width: 100%;
      }
    }

    &.warning {
      .description-text {
        color: $warningYellow !important;
      }
    }

    &.error {
      .description-text {
        color: $errorRed !important;
      }
    }
  }

  &.dropdown-button {
    border-bottom: none;

    .action-icon {
      border-radius: 0px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    &.open {
      .action-icon {
        margin-top: calc($spacer1x * 1.5);
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
      }
    }

    &.closed {
      .action-icon {
        margin-top: calc($spacer1x * -1);
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }

    .description-text {
      font-size: $fontSize;
      max-width: unset;
      width: $spacer28x;
    }

    .text-container {
      flex-direction: row-reverse;
    }

    .title-text {
      max-width: unset;
      width: auto;
    }

    &.default,
    &.warning,
    &.error {
      .description-text {
        align-items: center;
        display: flex;
        width: max-content;
      }

      .icon {
        height: $spacer6x;
        margin-right: $spacer1x;
      }

      .text-container {
        flex-direction: column;
        gap: calc($spacer1x / 2);
      }
    }

    &.default {
      .description-text {
        width: 100%;
      }
    }

    &.warning {
      .description-text {
        color: $warningYellow !important;
      }
    }

    &.error {
      .description-text {
        color: $errorRed !important;
      }
    }
  }
}
