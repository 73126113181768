@import "../../../styles/variables";

.giving-history {
  height: 100% !important;

  .screen-1,
  .screen-2 {
    display: flex;
    flex-direction: column;
    min-height: 0;

    .action-bar {
      flex-shrink: 0;
    }

    .screen-content {
      flex-grow: 1;
      min-height: 0;
      overflow: auto;
    }
  }

  .action-bar {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: $spacer12x;
    justify-content: space-between;

    .giving-statements-button {
      align-items: center;
      border-bottom: none;
      column-gap: $spacer1x;
      display: flex;
      flex-direction: row;
      padding-right: $spacer2x;

      .action-icon {
        border: solid $gray80;
        border-radius: 0px;
        border-width: 0 2px 2px 0;
        left: calc($spacer1x * 1.5);
        padding: $spacer1x;
        position: relative;
        top: calc(-1 * calc($spacer1x / 2));
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }

  .paperless-prompt {
    align-items: flex-end;
    background-color: $white;
    border-bottom: 1px solid $gray10;
    border-radius: $spacer2x;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: $spacer2x;
    margin-bottom: $spacer2x;
    padding: $spacer4x;
    position: relative;
    text-align: left;
    width: 100%;

    h2,
    p {
      margin: 0;
    }

    h2 {
      font-size: calc($fontSize + 4px);
      margin-top: $spacer1x;
    }

    p {
      font-size: calc($fontSize - 2px);
      line-height: 160%;
    }

    .btn-lineup {
      button {
        margin-bottom: 0;
      }
    }
  }

  .gift-detail-list-item {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $gray10;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    font-size: calc($fontSize + $spacer1x);
    justify-content: space-between;
    line-height: calc($fontSize * 2);
    padding: $spacer4x;
    padding-bottom: calc($spacer4x - 2px);
    width: 100%;

    .list-item-label {
      font-weight: 400;
      text-align: left;
    }

    .list-item-value {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: $spacer4x;
      font-weight: 700;
      text-align: right;
    }
  }

  .list {
    margin-bottom: $spacer4x;

    &.grouped {
      overflow: hidden;

      .list-item {
        border-radius: 0;

        &:first-of-type {
          margin-top: 0 !important;
        }
      }
    }
  }

  .list-item {
    img {
      height: auto;
      margin-bottom: calc($spacer1x / 2);
      width: $spacer8x;
    }
  }

  .no-history {
    align-items: center;
    display: flex;
    height: 80%;
    justify-content: center;
    text-align: center;

    > div {
      height: auto;
      width: 100%;
    }

    h2 {
      font-size: calc($fontSize * 3);
      font-weight: 900;
      line-height: 120%;
      margin: 0 0 $spacer4x;
      padding: 0 $spacer2x;

      @media screen and (min-width: $md) {
        padding: 0 $spacer8x;
      }
    }

    p {
      font-size: calc($fontSize + $spacer1x);
      line-height: calc($spacer5x + 2px);
      margin: $spacer4x 0 $spacer6x;
    }
  }

  .giving-history-loading-wrapper {
    left: unset !important;
    right: unset !important;
    width: calc(
      100% / 2 - calc($spacer1x * 4)
    ) !important; // Side padding of sub-screen.
  }
}
