@import "../../styles/variables";

.select-label {
  color: $black;
  cursor: pointer;
  display: flex;
  min-width: 0;

  * {
    cursor: pointer;
  }

  p {
    margin: 0;
  }

  .action-button,
  .label-control,
  .select-contents {
    min-width: 0;
  }

  .action-icon {
    border: solid $gray80;
    border-radius: 0px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    margin: -6px 0 0 0;
    padding: $spacer1x;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  &.open {
    .action-icon {
      margin-top: 2px;
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }
  }

  &.closed {
    .action-icon {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .icon {
    align-items: center;
    display: flex;
  }

  .label-control {
    align-items: center;
    display: flex;
    gap: $spacer2x;
  }

  .select-contents {
    align-items: center;
    display: flex;
    font-size: calc($fontSize + 4px) !important;
    font-weight: 700;
    gap: $spacer4x;
  }

  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /**
   * iOS-specific styles.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens}
   */
  @supports (-webkit-hyphens: none) {
    .action-icon {
      border-radius: 0px;
      margin-top: -4px !important;
    }

    &.open {
      .action-icon {
        margin-top: 4px !important;
      }
    }
  }
}
