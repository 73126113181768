@import "../../styles/variables";

.amount-wrapper {
  align-items: center;
  background-color: $white;
  border-color: transparent;
  border-radius: $spacer2x;
  border-style: solid;
  border-width: 2px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: space-around;
  margin: $spacer2x 0 $spacer6x;
  overflow: hidden;
  padding: $spacer1x $spacer4x 0;
  position: relative;
  transition: border 150ms ease-out;
  width: auto;

  &:hover {
    border-color: $gray10;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    .select-label {
      flex-shrink: 0;
    }
  }

  .select-label {
    margin: 0 $spacer4x;
    padding: 0;
    transition: all 0.5s ease-in;

    p {
      margin: 0;
    }

    &.horizontal {
      margin-right: $spacer1x !important;
      position: absolute;
      right: $spacer4x;
      top: calc($spacer8x + 2px);
    }

    &.vertical {
      display: flex;
      justify-content: center;
    }

    .action-icon {
      border-radius: 0px;
      margin-top: -6px;
    }

    &.open {
      .action-icon {
        margin-top: 2px;
      }
    }

    .truncate {
      overflow: auto;
      text-overflow: unset;
      white-space: unset;
      width: auto;
    }
  }

  &.horizontal {
    .amount-field {
      justify-content: flex-start !important;
    }

    .row {
      .select-label {
        &.horizontal {
          opacity: 1;
          pointer-events: all;
          transition: all 0.1s ease-in;
          z-index: 999;
        }

        &.vertical {
          opacity: 0;
          pointer-events: none;
          transition: all 0.3s ease-in;
        }
      }
    }
  }

  &.vertical {
    gap: $spacer1x;
    padding-bottom: $spacer2x;

    .amount-field {
      justify-content: center !important;
    }

    .row {
      .select-label {
        &.horizontal {
          opacity: 0;
          pointer-events: none;
          transition: all 0.1s ease-in;
        }

        &.vertical {
          opacity: 1;
          pointer-events: all;
          transition: all 0.3s ease-in;
          z-index: 999;
        }
      }
    }
  }

  &.focus {
    &.error {
      animation: shake 300ms;
      border-color: $errorRed;
    }

    &.green {
      border-color: $givingGreen;
    }
  }

  @media screen and (min-width: $md) {
    gap: $spacer2x;
    padding-bottom: 0;

    .select-label {
      margin: 0 $spacer1x 0 0;
    }
  }

  div.giving-amount-height {
    box-sizing: border-box;
    font-size: calc($fontSize * 4.5);
    height: auto;
    padding: 0;
    width: 1px;
  }

  /**
   * iOS-specific styles.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens}
   */
  @supports (-webkit-hyphens: none) {
    padding: 0 $spacer4x 0 !important;

    .select-label {
      &.horizontal {
        top: calc($spacer8x + 4px);
      }

      &.vertical {
        margin-bottom: $spacer2x;
      }

      .action-icon {
        border-radius: 0px;
        margin-top: -4px !important;
      }

      &.open {
        .action-icon {
          margin-top: 4px !important;
        }
      }
    }
  }
}
