@import "../../../styles/variables";

.payment-methods {
  .list {
    &.add-payment-method {
      .form-control.list-item {
        height: 80px;
      }
    }
  }

  .form {
    label {
      font-size: calc($fontSize - 1px);
      line-height: 160%;
      margin-bottom: $spacer1x;
      text-transform: uppercase;
    }

    input,
    div.StripeElement {
      background: $white;
      border: 1px solid $gray30;
      border-radius: $spacer2x;
      box-sizing: border-box;
      font-size: $fontSize;
      margin-bottom: $spacer4x;
      outline: none !important;
      padding: $spacer4x;
    }

    input {
      // Remove/hide arrows/spinners.
      &[type="number"]::-webkit-inner-spin-button,
      &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    div.StripeElement--empty {
      border-color: $gray30;
    }

    div.StripeElement--invalid {
      border-color: $errorRed;
    }

    div.StripeElement--complete,
    div.StripeElement--valid {
      border-color: $validGreen;
    }

    .form-control {
      cursor: text;
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    .g-recaptcha {
      display: flex;
      justify-content: center;
      margin: $spacer4x 0 $spacer4x;

      &.new-card {
        margin-top: $spacer6x;
      }

      // To fix challenge popup, transform scale and move to left side of page.
      @media only screen and (max-width: $md) {
        justify-content: flex-start;
        transform: scale(0.75);
        transform-origin: 0;
      }
    }
  }

  .loading {
    &.flex-center {
      margin: 0 auto;
    }
  }
}
