$spaceSizes: (
  0: 0px,
  4: 4px,
  8: 8px,
  16: 16px,
  24: 24px,
  32: 32px,
  36: 36px,
  48: 48px,
  64: 64px,
  auto: auto,
  none: 0,
);

$spaceTypes: (
  column-gap: column-gap,
  gap: gap,
  m: margin,
  p: padding,
  row-gap: row-gap,
  width: width,
);

$spaceDirections: (
  t: top,
  b: bottom,
  l: left,
  r: right,
);

// Spacing: Margin and Padding > x, y, top, bottom, l, r.
@each $spaceType, $spaceValue in $spaceTypes {
  @each $spaceSize, $sizeValue in $spaceSizes {
    .#{$spaceType}-#{$spaceSize} {
      #{$spaceValue}: $sizeValue !important;
    }

    .#{$spaceType}x-#{$spaceSize} {
      #{$spaceValue}-left: $sizeValue !important;
      #{$spaceValue}-right: $sizeValue !important;
    }

    .#{$spaceType}y-#{$spaceSize} {
      #{$spaceValue}-top: $sizeValue !important;
      #{$spaceValue}-bottom: $sizeValue !important;
    }

    @each $spaceDir, $dir in $spaceDirections {
      .#{$spaceType}#{$spaceDir}-#{$spaceSize} {
        #{$spaceValue}-#{$dir}: $sizeValue !important;
      }
    }
  }
}

// Colors
.gray100 {
  color: $gray100;
}

// Sizing
.full-width {
  width: 100%;
}

// Text
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

// Badges
.badge {
  align-items: center;
  border-radius: $spacer1x;
  display: flex;
  flex-direction: row;
  font-size: calc($fontSize - 2px);
  line-height: calc($fontSize);
  padding: $spacer1x $spacer3x $spacer1x $spacer2x;
  width: max-content;

  &.error {
    background-color: $errorRedBadgeBg;
    color: $errorRedBadge;
  }

  &.warning {
    background-color: $warningYellowBadgeBg;
    color: $warningYellowBadge;
  }

  .icon {
    line-height: calc($fontSize / 2);
    margin-right: $spacer1x;
  }
}

// Icons
.svg-icon {
  line-height: 0;
}

// Display + Floats
hr.divider {
  border: none;
  border-bottom: 1px solid $gray30;
  height: 1px;
  margin: $spacer8x 0;
  opacity: 0.5;
  width: auto;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.position-relative {
  position: relative;
}

.z-9x7 {
  z-index: 9999999;
}

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.ai-center {
    align-items: center !important;
  }

  &.ai-end {
    align-items: flex-end !important;
  }

  &.ai-start {
    align-items: flex-start !important;
  }

  &.justify {
    &.center {
      justify-content: center;
    }

    &.space-between {
      justify-content: space-between;
    }
  }
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.float-right {
  float: right;
}

// Grids
.grid-item {
  float: left;
}

.g-1 {
  width: calc(100% / 12);
}

.g-2 {
  width: calc(calc(100% / 12) * 2);
}

.g-3 {
  width: calc(calc(100% / 12) * 3);
}

.g-4 {
  width: calc(calc(100% / 12) * 4);
}

.g-5 {
  width: calc(calc(100% / 12) * 5);
}

.g-6 {
  width: calc(calc(100% / 12) * 6);
}

.g-7 {
  width: calc(calc(100% / 12) * 7);
}

.g-8 {
  width: calc(calc(100% / 12) * 8);
}

.g-9 {
  width: calc(calc(100% / 12) * 9);
}

.g-10 {
  width: calc(calc(100% / 12) * 10);
}

.g-11 {
  width: calc(calc(100% / 12) * 11);
}

.g-12 {
  width: 100%;
}

.row {
  width: 100%;

  > *:last-child {
    float: right;
  }
}

@media screen and (min-width: $md) {
  [class^="g-"] {
    float: none;
    width: 100%;
  }
}
