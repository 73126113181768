@import "../../../styles/variables";

.process {
  display: flex;
  justify-content: center;
  margin: $spacer2x;

  .process-text {
    color: $gray80;
    font-size: $fontSize;
  }
}

.process-date {
  display: block;
}

.react-datepicker {
  border: 0;
  border-radius: $spacer4x;
  font-family: $defaultFontFamily;
  width: 100%;

  .react-datepicker__header {
    background-color: $white;
    border: 0;
    border-radius: $spacer4x;
    margin: auto;
  }

  &__current-month {
    color: $black;
    font-size: $spacer5x;
    font-weight: 600;
    padding: $spacer2x;
  }

  &__month-container {
    width: 100%;
  }

  &__day {
    font-size: $spacer5x;
    font-weight: 400;

    &:hover {
      background-color: $gray30;
      border-radius: 0;
      color: $white;
    }
  }

  &__day--selected {
    background-color: $linkColor;
    border-radius: 0;
    color: $white !important;

    &:hover {
      background-color: $linkColor;
      border-radius: 0;
    }
  }

  &__day-name {
    color: $gray80;
    font-size: 14px;
    font-weight: 600;
    padding-bottom: 0 !important;
    text-transform: uppercase;
  }

  &__navigation--next {
    padding: $spacer2x;
    right: $spacer3x;
    top: $spacer5x;
  }

  &__navigation--previous {
    left: $spacer3x;
    padding: $spacer2x;
    top: $spacer5x;
  }

  &__day-name,
  &__day,
  &__time-name {
    border-radius: 0;
    display: inline-block;
    margin: 0;
    padding-bottom: $spacer2x;
    padding-top: $spacer2x;
    text-align: center;
    width: calc(100% / 7);
  }

  &__navigation-icon::before {
    border-color: $linkColor !important;
  }

  &__day--outside-month {
    background-color: transparent;
    color: transparent;
    pointer-events: none;
  }

  &__day--today {
    color: $linkColor;
  }

  &__day--keyboard-selected {
    background-color: $white;
  }
}
