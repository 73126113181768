@import "./_reset.scss";
@import "./_fonts.scss";
@import "./_variables.scss";
@import "./_buttons.scss";
@import "./_forms.scss";
@import "./_loaders.scss";
@import "./_helpers.scss";

html,
body {
  background-color: $bodyBackground;
  font-family: $defaultFontFamily;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

h1 {
  margin: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: black;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding-left: 0;
  padding-right: 0;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a {
  text-decoration: none;
  color: $linkColor;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input {
  appearance: none;

  &.focus {
    &.error {
      border-color: $errorRed !important;
      caret-color: $errorRed !important;
      color: $errorRed !important;
    }
  }

  &.error {
    border-color: $errorRed !important;
    caret-color: $errorRed !important;
    color: $errorRed !important;
  }
}

label {
  &.error {
    color: $errorRed !important;

    > span.prompt {
      color: $black !important;
    }
  }
}

.container {
  font-size: $fontSize;
  max-width: $contentMaxWidth;
  padding: $spacer3x $spacer4x $spacer4x;
  text-align: left;
  margin: 0 auto;

  @media screen and (min-width: $md) {
    margin-left: auto;
    margin-right: auto;
    max-width: $contentMaxWidth;
  }
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.backdrop {
  backface-visibility: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgb(0, 0, 0, 0.65);
  bottom: 0;
  -webkit-backface-visibility: hidden;
  display: block;
  height: 100%;
  left: 0;
  margin: 0;
  max-width: none;
  min-height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 599999;

  &.dialog {
    z-index: 6999999;
  }

  &.open {
    animation-name: opacity-show;
    animation-duration: 0.3s;
  }

  &.close {
    animation-name: opacity-hide;
    animation-duration: 0.3s;
  }
}

.btn-lineup {
  display: flex;
  gap: $spacer2x;

  button,
  .btn {
    flex-grow: 1;
    margin-left: 0;
    margin-right: 0;
  }
}

.content-center {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.grouped {
  background: $white;
  border-radius: 8px;
  overflow: hidden;

  .list-item.form-control {
    &:last-of-type {
      border-bottom: none;
    }
  }
}

.full-and-absolute {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  &.transparent {
    background: transparent;
    border-color: transparent;
  }
}

.paperless-preference-setting-wrapper,
.recurring-gift-wrapper {
  position: relative;

  .toggle-switch {
    margin: 0;
    position: absolute;
    right: $spacer4x;
    top: calc($spacer4x + 2px);
  }
}

.give-button,
.btn.give-button {
  font-weight: 600;
  margin: $spacer6x 0 0 0 !important;
  width: 100%;
}
