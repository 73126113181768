@import "../../styles/_variables.scss";

.confirmation {
  padding: $spacer4x 0px;
  width: 100%;

  h1 {
    font-size: calc($fontSize * 2.25);
    font-weight: 700;
    line-height: 120%;

    @media screen and (min-width: $md) {
      font-size: calc($fontSize * 3);
    }
  }

  p {
    &.main-message,
    &.sub-message {
      font-size: calc($fontSize + $spacer1x);
    }

    &.main-message {
      color: $gray100;
    }

    &.sub-message {
      color: $gray80;
    }
  }

  .next-action-wrapper {
    background-color: $white;
    border-radius: $spacer3x;
    padding: $spacer8x;
    width: calc(100% - $spacer16x);

    .contents {
      color: $white !important;
      padding: 60px 0 0;

      h2 {
        color: $white;
        margin-bottom: $spacer2x;
      }

      p {
        color: $white;
        font-size: calc($fontSize + $spacer1x);
        font-weight: 700;
        line-height: calc($fontSize + 10px);
      }

      @media screen and (min-width: $md) {
        padding-left: 0;
        padding-right: 0;
        padding-top: 36px;
      }
    }
  }

  .secondary-content {
    color: $gray80;
    padding-top: $spacer2x;

    a {
      color: inherit;
      text-decoration: underline;

      &.bold {
        font-weight: 700;
      }

      &.no-decoration {
        text-decoration: none;
      }

      &.back-to-lc-site {
        font-size: 17px;
      }
    }
  }

  @media screen and (min-width: $md) {
    width: unset;
  }
}
