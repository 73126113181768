@import "../../styles/variables";

.reveal {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  background-color: transparent;
  display: block;
  height: 100%;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 6000000;

  &.full {
    border: none;
    border-radius: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-width: none;
    min-height: 100%;
    right: 0;
    top: 0;
    width: 100%;

    &.animate-rtl {
      &.open {
        animation-name: open-modal-rtl, opacity-show;
        animation-duration: 0.3s;
      }
    }

    &.open {
      animation-name: open-modal, opacity-show;
      animation-duration: 0.3s;
    }

    &.close {
      animation-name: opacity-hide;
      animation-duration: 0.3s;
    }
  }

  &.dialog {
    z-index: 7000000;

    .modal {
      height: auto;
      max-width: 400px;
    }
  }
}

.modal {
  background-color: $modalBackground;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 15px rgba(47, 47, 47, 0.3);
  display: grid;
  grid-template-areas: "header" "content" "footer";
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  position: relative;
  margin: 0;
  width: 100%;
  z-index: 6000000;

  .modal-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    grid-area: header;
    gap: $spacer3x;
    justify-content: space-between;
    margin: 0;
    padding: 19px 54px 16px;
    position: relative;
    z-index: 5000001;

    > div {
      width: 100%;
    }

    .btn-modal-close {
      height: calc($fontSize * 2);
      left: 16px;
      padding: $spacer1x;
      position: absolute;
      width: calc($fontSize * 2);

      .icon {
        display: block;
        height: $fontSize;
        position: relative;
        width: $fontSize;

        &::after {
          border-left: 2px solid $gray80;
          content: "";
          height: calc($fontSize);
          left: calc($fontSize / 2);
          position: absolute;
          transform: rotate(45deg);
        }

        &::before {
          border-left: 2px solid $gray80;
          content: "";
          height: calc($fontSize);
          left: calc($fontSize / 2);
          position: absolute;
          transform: rotate(-45deg);
        }
      }
    }

    .btn-modal-right {
      padding: 4px;
      position: absolute;
      right: 10px;
    }

    .modal-title {
      font-size: calc($fontSize + 4px);
      font-weight: 700;
      line-height: 160%;
      margin: 0 auto;
      text-align: center;
      width: 100%;
    }

    .modal-header-mode-back {
      .btn-modal-close {
        .icon {
          border: solid $gray80;
          border-width: 0 2px 2px 0;
          display: inline-block;
          height: auto;
          margin: -3px 3px 0px;
          padding: $spacer1x;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
          width: 0;

          &::after,
          &::before {
            border-color: transparent;
          }
        }
      }
    }
  }

  .modal-content {
    grid-area: content;
    height: 100%;
    margin: auto;
    overflow: auto;
    padding: $spacer4x;
    width: calc(100% - $spacer8x);
    z-index: 5000000;

    .confirmation-modal {
      padding-top: 30px;

      p {
        font-size: $fontSize;
        line-height: 160%;
      }

      // Note: Confirmation modal has 30px top padding, but icon should be closer to the top.
      .icon-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: $spacer2x;
        margin-top: calc(-1 * $spacer4x);
      }
    }

    .confirm-title {
      font-size: calc($fontSize * 2);
      line-height: 1;
      margin: 0 0 $spacer4x;
    }

    .confirm-message {
      &:first-of-type {
        margin-top: 0;
      }
    }

    .loading-wrapper {
      align-items: center;
      background-color: rgba(242, 242, 247, 0.65);
      bottom: 0;
      display: flex;
      justify-content: center;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;

      .loading {
        &.flex-center {
          margin: 0;
        }
      }
    }

    p {
      &.disclaimer {
        color: $gray80;
        font-size: calc($fontSize - 2px);
      }

      &.section-title {
        color: $gray100;
        text-transform: uppercase;
      }
    }

    &.animatable {
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      width: calc(100% - $spacer8x);

      .animatable-content {
        display: flex;
        flex-direction: row;
        gap: $spacer8x;
        transform: translate(0, 0);
        transition: transform 300ms ease-in-out;
        width: calc(200% + $spacer8x);

        > div {
          width: 100%;
        }

        &.active-1 {
          transform: translate(0%, 0);
        }

        &.active-2 {
          transform: translate(calc(-1 * calc(50% + 16px)), 0);
        }

        &.thirds {
          width: calc(300% + $spacer16x);

          &.active-2 {
            transform: translate(
              calc(-1 * calc(calc(100% / 3) + $spacer3x)),
              0
            );
          }

          &.active-3 {
            transform: translate(
              calc(calc(-2 * calc(100% / 3)) + calc(-2 * calc($spacer8x / 3))),
              0
            );
          }
        }
      }
    }
  }

  .modal-footer {
    background-color: $white;
    box-shadow: $modalBackground;
    grid-area: footer;
    margin: 0;
    padding: $spacer4x;
    z-index: 5000002;

    button,
    .btn {
      &.full-width {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .disclaimer {
      font-size: calc($fontSize - 2px);
      line-height: calc($fontSize + $spacer1x);
    }
  }

  .grouped {
    background: $white;
    border-radius: 8px;
  }

  .list {
    .list-item,
    .radio-list-item {
      margin: 0;

      &:first-of-type {
        margin-top: 0;
      }
    }

    .list-container {
      background: $white;
      border-radius: $spacer2x;

      .list-item,
      .radio-list-item {
        margin-top: 0;

        &:first-of-type {
          margin-top: $spacer2x;
        }
      }
    }

    &.add-payment-method {
      color: $gray100;
      text-align: center;

      .inline-list-instructions {
        color: $gray100 !important;
        text-align: center;
      }

      .paypal-button {
        width: 100%;

        &:hover {
          background-color: transparent !important;
          border: 1px solid rgba(0, 158, 204, 0.2) !important;
        }
      }
    }

    p.month,
    p.section-label {
      color: $gray100;
      margin-top: 0;
      text-transform: uppercase;
    }

    .icon-lineup {
      display: flex;
      gap: $spacer2x;
    }
  }
}

@media screen and (min-width: $md) {
  .reveal {
    &.dialog {
      .modal {
        height: auto;
        margin: 0 auto;
        max-width: 400px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .modal {
    border-radius: $spacer3x;
    height: calc(100vh - $spacer36x);
    margin: $spacer18x auto;
    max-height: 800px;
    width: 500px;
  }
}

@media only screen and (max-width: $md) {
  .reveal {
    &.dialog {
      .modal {
        height: auto;
        margin: 0 auto;
        max-height: -webkit-fill-available;
        min-height: unset;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .modal {
    // Mobile viewport bug fix.
    max-height: -webkit-fill-available;
    min-height: -webkit-fill-available;
  }
}
