@import "../../styles/variables";

#auth-menu,
.auth-menu {
  align-items: center;
  display: flex;
  gap: $spacer4x;
  justify-content: space-between;
  width: 100%;

  ul {
    justify-content: flex-end;
    list-style: none;
    padding: 0;
  }

  li {
    margin: 0.5rem 0.375rem;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      align-items: center;
      color: $black;
      display: flex;
      font-size: $fontSize;
      font-style: normal;
      font-weight: 400;
    }
  }

  img.avatar {
    border-radius: 100px;
    height: 2rem;
    object-fit: cover;
    width: 2rem;
  }

  &.giving-menu-auth {
    li {
      margin: 0;
    }
  }

  .help-nav {
    li {
      &:first-of-type {
        margin-left: 0;
        margin-right: 0.375rem;

        @media screen and (min-width: $md) {
          margin: 0.5rem 0.375rem;
        }
      }
    }
  }

  .menu-nav {
    align-items: center;
    display: flex;
    text-align: right;

    @media screen and (min-width: $md) {
      text-align: left;
    }
  }

  &.justify {
    &.flex-end {
      justify-content: flex-end;
    }
  }

  @media screen and (min-width: $md) {
    justify-content: flex-end;
    width: auto;
  }
}
