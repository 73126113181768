@import "../../styles/_variables.scss";

.maintenance-outage {
  margin: 0 auto;
  padding: $spacer8x $spacer4x $spacer6x;
  text-align: center;
  width: calc(100% - $spacer8x);

  h1 {
    font-size: calc($fontSize * 2.25);
    font-weight: 900;
    line-height: 120%;

    @media screen and (min-width: $md) {
      font-size: calc($fontSize * 3);
    }
  }

  p {
    color: $black;
    font-size: calc($fontSize + $spacer1x);
  }

  @media screen and (min-width: $md) {
    padding: $spacer8x 0px $spacer6x;
    width: 500px;
  }
}
