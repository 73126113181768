@import "@lifechurch/web-tools-io/dist/themes/base/_colors.scss";

$contentMaxWidth: 600px;

// Break points
$md: 640px;
$lg: 1024px;
$xl: 1200px;

// Colors
$bodyBackground: #f2f2f7;
$black: $black;
$errorRed: $errorRed;
$errorRedBadge: #ad3612;
$errorRedBadgeBg: #ffe9e1;
$givingGreen: $validGreen;
$givingGreenHover: $validGreenHover;
$givingGreenPressed: $validGreenPressed;
$gray5: map-get($gray, 5);
$gray10: map-get($gray, 10);
$gray30: map-get($gray, 30);
$gray50: map-get($gray, 50);
$gray80: map-get($gray, 80);
$gray100: map-get($gray, 100);
$gray130: map-get($gray, 130);
$linkColor: $primary;
$primaryBlue: $primary;
$warningYellow: $warningYellow;
$warningYellowBadge: #916700;
$warningYellowBadgeBg: #fff7e0;
$white: $white;

// Fonts
$defaultFontFamily: "Proxima Nova", proxima_nova, "Helvetica Neue", Helvetica,
  Arial, sans-serif;

// Modals
$modalBackground: #f2f2f7;

// Sizing
$fontSize: 16px;

$spacer1x: 4px;
$spacer2x: calc($spacer1x * 2); // 8px
$spacer3x: calc($spacer1x * 3); // 12px
$spacer4x: calc($spacer1x * 4); // 16px
$spacer5x: calc($spacer1x * 5); // 20px
$spacer6x: calc($spacer1x * 6); // 24px
$spacer8x: calc($spacer1x * 8); // 32px
$spacer10x: calc($spacer1x * 10); // 40px
$spacer12x: calc($spacer1x * 12); // 48px
$spacer16x: calc($spacer1x * 16); // 64px
$spacer18x: calc($spacer1x * 18); // 72px
$spacer24x: calc($spacer1x * 24); // 96px
$spacer28x: calc($spacer1x * 28); // 112px
$spacer32x: calc($spacer1x * 32); // 128px
$spacer36x: calc($spacer1x * 36); // 144px

// Animations

@keyframes shake {
  0% {
    transform: translateX(0px);
    -moz-transform: translateX(0px);
  }
  33% {
    transform: translateX(4px);
    -moz-transform: translateX(4px);
  }
  66% {
    transform: translateX(-4px);
    -moz-transform: translateX(-4px);
  }
  100% {
    transform: translateX(0px);
    -moz-transform: translateX(0px);
  }
}

@keyframes open-modal {
  from {
    transform: translateY(24px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes open-modal-rtl {
  from {
    transform: translateX(24px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes opacity-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes opacity-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
