@import "../../../styles/variables";

.manage-giving {
  height: 100% !important;

  .screen-1,
  .screen-2 {
    display: flex;
    flex-direction: column;
    min-height: 0;

    .action-bar {
      flex-shrink: 0;
    }

    .screen-content {
      flex-grow: 1;
      min-height: 0;
      overflow: auto;
    }
  }

  .list {
    margin-bottom: $spacer4x;

    &.grouped {
      overflow: hidden;

      .list-item {
        border-radius: 0;

        &:first-of-type {
          margin-top: 0 !important;
        }
      }
    }
  }

  .list-item {
    img {
      height: auto;
      margin-bottom: calc($spacer1x / 2);
      width: $spacer8x;
    }
  }

  .no-manage-giving {
    align-items: center;
    display: flex;
    height: 80%;
    justify-content: center;
    text-align: center;

    > div {
      height: auto;
      width: 100%;
    }

    h2 {
      font-size: calc($fontSize * 3);
      font-weight: 900;
      line-height: 120%;
      margin: 0 0 $spacer4x;
      padding: 0 $spacer2x;

      @media screen and (min-width: $md) {
        padding: 0 $spacer8x;
      }
    }

    p {
      font-size: calc($fontSize + $spacer1x);
      line-height: calc($spacer5x + 2px);
      margin: $spacer4x 0 $spacer6x;
    }
  }
}
