@import "../../../styles/variables";

.amount-field {
  &.form-control {
    align-items: normal;
    background-color: transparent;
    border-radius: $spacer2x;
    display: flex;
    flex-grow: 1;
    font-family: $defaultFontFamily;
    font-weight: 700;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0;
    width: auto;

    .error {
      color: $errorRed !important;
    }

    label.currency-sign,
    input,
    div.giving-amount {
      caret-color: auto;
      color: $black;
      display: inline-block;
      float: none;
      font-family: $defaultFontFamily;
      vertical-align: top;
    }

    div.giving-amount {
      display: inline-block;
      overflow: auto;
      position: fixed;
      visibility: hidden;
      width: auto !important;
    }

    label.currency-sign {
      cursor: pointer;
      font-size: calc($fontSize * 2);
      padding-top: $spacer3x;

      &.error {
        caret-color: $errorRed !important;
        color: $errorRed !important;
      }

      &.green {
        caret-color: $givingGreen !important;
        color: $givingGreen;
      }
    }

    input:invalid {
      animation: shake 300ms;
      caret-color: $errorRed !important;
      color: $errorRed !important;
    }

    input {
      cursor: pointer;
    }

    input,
    div.giving-amount {
      border: none !important;
      border-radius: $spacer2x;
      box-sizing: border-box;
      font-size: calc($fontSize * 4.5);
      height: auto;
      padding: 0;

      &:active,
      &:hover,
      &:focus {
        border: none !important;
        outline: none !important;
      }

      &:focus {
        caret-color: $givingGreen !important;
        color: $givingGreen;
      }

      &.error {
        &:focus {
          caret-color: $errorRed !important;
          color: $errorRed;
        }
      }

      // Remove/hide arrows/spinners.
      &[type="number"]::-webkit-inner-spin-button,
      &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        /* Firefox 19+ */
        appearance: textfield;
        -moz-appearance: textfield;
      }

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $gray30;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $gray30;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $gray30;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: $gray30;
      }

      @media screen and (min-width: $md) {
        width: unset;
      }
    }

    @media screen and (min-width: $md) {
      width: min-content;

      input {
        padding: 0;
      }
    }

    /**
     * iOS-specific styles.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-touch-callout}
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens}
     */
    @supports (-webkit-touch-callout: none) {
      label.currency-sign {
        padding-top: $spacer4x;
      }

      input.giving-amount {
        height: auto !important;
      }
    }

    @supports (-webkit-hyphens: none) {
      label.currency-sign {
        padding-top: $spacer4x;
      }

      input.giving-amount {
        height: auto !important;
      }
    }
  }
}
