@import "./_variables.scss";

.loader {
  &.circular {
    animation: circularRotation 0.8s linear infinite;
    border: 3px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    display: inline-block;
    height: $spacer6x;
    width: $spacer6x;
  }

  &.gray100 {
    border-color: $gray100;
    border-bottom-color: transparent;
  }
}

@keyframes circularRotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
