@import "../../styles/variables";

.loading {
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  position: relative;
  right: 0;
  text-align: center;
  top: 0;

  &.flex-center {
    display: flex;
    justify-content: center;
    margin: -100px auto 0; // Offset upward to account for img size.
    text-align: center;
  }

  img {
    height: auto;
    width: 100px;
  }
}
