@import "../../styles/variables";

.radio-list-item {
  &.form-control {
    background-color: $white;
    border-bottom: 1px solid $gray5;
    border-radius: $spacer2x;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    gap: $spacer2x;
    grid-template-columns: auto;
    width: 100%;

    // Ensure all children inherit cursor pointer.
    * {
      cursor: inherit;
    }

    &:focus-within {
      color: inherit;
    }

    .label {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: $spacer4x;
      padding: $spacer4x;

      .content-horizontal,
      .content-vertical {
        display: flex;
        width: 100%;
      }

      .content-horizontal {
        align-items: center;
        flex-direction: row;
        gap: $spacer4x;
      }

      .content-vertical {
        align-items: flex-start;
        flex-direction: column;
        gap: $spacer3x;

        .text-container {
          gap: $spacer2x;
        }

        .description-text {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          gap: $spacer2x;
        }

        .title-text {
          &.primary {
            margin-bottom: 0;
          }
        }
      }
    }

    .radio {
      border: 1px solid $black;
      border-radius: 100%;
      box-sizing: border-box;
      flex-shrink: 0;
      height: $spacer5x;
      margin: auto $spacer2x;
      order: 1;
      width: $spacer5x;

      &:checked {
        background-color: rgba(0, 158, 204, 0.2);
        border-color: $linkColor;
        border-width: 2px;
        box-shadow: 0 0 0 2px $linkColor;
        outline: none;

        &::before {
          transform: scale(1);
        }
      }

      input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: $linkColor;
        border: 1px solid $black;
        border-radius: 50%;
        color: currentColor;
        display: grid;
        font: inherit;
        height: $spacer5x;
        margin: 0;
        place-content: center;
        transform: translateY(1.2px);
        width: $spacer5x;

        &::before {
          background-color: CanvasText;
          border-radius: 50%;
          box-shadow: inset $spacer3x $spacer3x $linkColor;
          content: "";
          height: calc($spacer3x - 2px);
          transform: scale(0);
          transition: 120ms transform ease-in-out;
          width: calc($spacer3x - 2px);
        }
      }
    }

    .description-text {
      align-items: center;
      color: $gray100;
      display: flex;
      font-size: calc($fontSize - 2px);
      font-weight: 400;
      line-height: calc($fontSize + $spacer1x);
      max-width: max-content;
    }

    .icon {
      display: inline-flex;
    }

    .start-icon {
      height: auto;
      width: $spacer8x;
    }

    .label-text {
      color: $black;
      font-size: $fontSize;
      min-width: 100px;
      padding-bottom: $spacer2x;
      padding-top: $spacer2x;
    }

    .title-text {
      color: $black;
      font-size: $fontSize;
      padding-bottom: $spacer2x;
      padding-top: $spacer2x;

      &.primary {
        color: $black;
        font-size: calc($fontSize + 4px);
        font-style: normal;
        font-weight: 700;
        line-height: calc($fontSize + 6px);
        margin-bottom: $spacer1x;
        padding: 0;
      }
    }

    .current-location {
      justify-content: center;
    }

    .my-location {
      justify-content: center;
    }

    .chip-container {
      display: flex;
      flex-direction: row;
      gap: $spacer2x;
    }

    .text-container {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: left;
      text-align: left;
    }

    &.warning,
    &.error {
      .icon {
        margin-right: $spacer1x;
      }
    }

    &.warning {
      .description-text {
        color: $warningYellow !important;
      }
    }

    &.error {
      .description-text {
        color: $errorRed !important;
      }
    }
  }
}
