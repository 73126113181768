@import "../../styles//variables";

.footer {
  color: $gray100;
  font-size: calc($fontSize - 2px);
  margin-top: $spacer6x;
  text-align: center;

  a {
    color: inherit;
    text-decoration: underline;

    &.bold {
      font-weight: 700;
    }

    &.no-decoration {
      text-decoration: none;
    }

    &.back-to-lc-site {
      font-size: 17px;
    }
  }

  p {
    color: inherit;
    margin: $spacer4x 0;
  }

  .primary-content {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: $spacer2x;
    justify-content: center;
    margin: auto;
    max-width: 300px;

    p {
      margin: 0;
    }
  }

  .secondary-content {
    color: $gray80;
    padding-top: $spacer2x;
  }
}
