@import "../../../styles/variables";

.manage-payment-method {
  height: 100% !important;

  .screen-1,
  .screen-2,
  .screen-3 {
    display: flex;
    flex-direction: column;
    min-height: 0;

    .action-bar {
      flex-shrink: 0;
    }

    .screen-content {
      flex-grow: 1;
      min-height: 0;
      overflow: auto;
    }
  }

  .list {
    margin-bottom: $spacer4x;
    padding-bottom: $spacer4x;

    &.grouped {
      overflow: hidden;

      .list-item {
        border-radius: 0;

        &:first-of-type {
          margin-top: 0 !important;
        }
      }
    }
  }

  .list-item {
    img {
      height: auto;
      margin-bottom: calc($spacer1x / 2);
      width: $spacer8x;
    }
  }

  .payment-method-detail {
    .loading {
      height: 100% !important;
    }
  }

  .no-manage-payment-method {
    align-items: center;
    display: flex;
    height: 80%;
    justify-content: center;
    text-align: center;

    > div {
      height: auto;
      width: 100%;
    }

    h2 {
      font-size: calc($fontSize * 3);
      font-weight: 900;
      line-height: 120%;
      margin: 0 0 $spacer4x;
      padding: 0 $spacer2x;

      @media screen and (min-width: $md) {
        padding: 0 $spacer8x;
      }
    }

    p {
      font-size: calc($fontSize + $spacer1x);
      line-height: calc($spacer5x + 2px);
      margin: $spacer4x 0 $spacer6x;
    }
  }

  .content-block {
    background-color: $white;
    border-radius: $spacer2x;
    box-sizing: border-box;
    display: grid;
    gap: $spacer4x;
    grid-template-columns: auto;
    padding: $spacer4x;
    width: 100%;

    .section {
      margin: 0;

      &.grid {
        gap: $spacer2x;
      }
    }

    .label-text {
      color: $gray100;
      font-size: calc($fontSize - 2px);
    }

    .title-text {
      color: $black;
      font-size: $fontSize;
      padding-bottom: $spacer2x;
      padding-top: $spacer2x;

      &.primary {
        color: $black;
        font-size: calc($fontSize + 4px);
        font-style: normal;
        font-weight: 700;
        line-height: calc($fontSize + 6px);
        margin-bottom: $spacer1x;
        padding: 0;
      }
    }
  }

  .loading-wrapper {
    width: calc(100% / 3) !important;

    .loading {
      &.flex-center {
        margin: calc($spacer18x * (-1)) 0 0 0 !important;
      }
    }
  }
}
