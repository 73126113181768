@import "./_variables.scss";

.form-container {
  display: flex;

  &.vertical {
    flex-direction: column;
    gap: $spacer4x;
  }

  &.list-container {
    background: $white;
    border-radius: $spacer2x;
    padding: $spacer4x;
  }
}

.form-field-control {
  display: flex;
  flex-direction: column;
  font-style: normal;
  gap: $spacer1x;
  width: 100%;

  label {
    color: $black;
    font-size: calc($fontSize + $spacer1x);
    font-weight: 700;
    line-height: calc($fontSize + $spacer3x);
    margin: 0;

    span.note {
      font-size: calc($fontSize - 2px);
      font-weight: 400;
    }
  }

  input[type="email"],
  input[type="number"],
  input[type="text"],
  select {
    background-color: $white;
    border: 1px solid $gray50;
    border-radius: $spacer2x;
    color: $black;
    cursor: text;
    font-size: calc($fontSize + $spacer1x);
    line-height: calc($fontSize + $spacer3x);
    padding: $spacer2x $spacer4x;

    &:focus {
      outline: none;
    }

    &:disabled {
      color: $gray50;
      cursor: not-allowed;
    }

    &.error {
      border-color: $errorRed;
    }
  }

  select {
    appearance: none;
    cursor: pointer;
    padding: $spacer2x $spacer3x;
    width: 100%;
    -webkit-appearance: none;
  }

  .custom-select {
    cursor: pointer;
    position: relative;
    width: 100%;

    &::after {
      border: solid $gray80;
      border-radius: 0px;
      border-width: 0 2px 2px 0;
      content: "";
      padding: $spacer1x;
      pointer-events: none;
      position: absolute;
      right: $spacer4x;
      top: 35%;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }

  .counter,
  .input-disclaimer {
    font-size: calc($fontSize - $spacer1x);
    line-height: $fontSize;

    &.error {
      color: $errorRed !important;
    }
  }
}
