@import "../../styles/_variables.scss";

.processing {
  background-color: $bodyBackground;
  bottom: 0;
  height: 100vh;
  left: 0;
  margin: 0;
  padding: $spacer16x $spacer4x;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 5999999;

  &.flex-center {
    display: flex;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
  }

  .processing-contents {
    position: relative;
    max-width: 350px;
    width: auto;
  }

  h2.status {
    font-size: calc($fontSize + $spacer1x);
    font-weight: 400;
    line-height: $spacer6x;
  }

  .quote,
  .reference {
    font-size: calc($fontSize + 1px);
    line-height: calc($spacer5x + 2px);
  }

  .quote {
    font-weight: 700;
  }

  .reference {
    color: $gray50;
    font-weight: 400;
  }

  img {
    height: auto;
    width: 100px;
  }
}

@media screen and (min-width: $md) {
  .processing {
    padding: $spacer24x $spacer4x;
  }
}
