@import "../../styles/variables";

.header {
  margin: auto;
  width: 100%;

  .navigation {
    align-items: flex-start;
    display: flex;
    gap: $spacer4x;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;

    @media screen and (max-width: $md) {
      &.inline {
        align-items: center;
        flex-direction: row;
        gap: 0;

        .select-label {
          flex-shrink: 1;
          width: 100%;
        }

        #auth-menu {
          flex-shrink: 2;
        }
      }
    }

    @media screen and (min-width: $md) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .icon-lineup {
    display: flex;
    gap: $spacer4x;
  }

  .select-label {
    &.dual-icons {
      .select-contents {
        gap: $spacer2x;
      }
    }
  }
}
