@import "../../styles/variables";

.icon-chip {
  background: #f2fafc;
  align-items: center;
  border-radius: $spacer1x;
  display: inline-flex !important;
  height: auto;
  margin: 0 auto $spacer1x auto;
  padding: $spacer1x $spacer2x;
  width: auto;

  .icon {
    margin-right: $spacer1x;
  }

  .icon-text {
    color: $linkColor;
    font-weight: 700;
    justify-content: center;
  }
}

.icon-circle {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: $spacer5x;
  justify-content: center;
  overflow: hidden;
  width: $spacer5x;

  &.blue {
    background-color: $primaryBlue;
  }

  &.yellow {
    background-color: $warningYellow;
  }

  .icon {
    height: auto;
    width: 50%;
  }
}
