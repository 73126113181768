@import "../../../styles/variables";

.paperless-preference {
  .list {
    margin-bottom: $spacer4x;

    &.grouped {
      overflow: hidden;

      .list-item {
        border-radius: 0;

        &:first-of-type {
          margin-top: 0 !important;
        }
      }
    }
  }
}
