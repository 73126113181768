@import "./_variables.scss";

.btn,
.styled-btn {
  &.black,
  &.green,
  &.btn-primary,
  &.btn-secondary,
  &.styled-btn-primary,
  &.styled-btn-secondary {
    &:disabled {
      background-color: $gray30 !important;
      border-color: transparent !important;
      color: $white !important;

      &:hover {
        box-shadow: none !important;
      }
    }
  }

  &.bold {
    font-weight: 700 !important;
  }

  &.btn-secondary,
  &.styled-btn-secondary {
    // Need to use !important to override fully and successfully.
    &:disabled {
      background-color: transparent !important;
      border: 1px solid $black !important;
      color: $black !important;
      opacity: 0.5 !important;
    }

    .icon {
      svg {
        opacity: 1;
        transition: all 0.3s ease-in-out;
      }
    }

    &:hover {
      .icon {
        svg {
          opacity: 0.5;
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &.reverse {
      border-color: $white;
      border-width: 1px;
      color: $white;
      transition: transform 0.3s ease-in-out;

      &:hover,
      &.pressed,
      &:active {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: $white;
        color: $white;
      }
    }
  }

  &.btn-tertiary,
  &.styled-btn-tertiary {
    &:disabled {
      color: $gray30 !important;
    }

    &.black {
      background-color: transparent;
      border-color: transparent;
      color: $gray100 !important;

      &:hover,
      &.pressed,
      &:active {
        background-color: transparent;
        border-color: transparent !important;
        color: $black !important;
      }

      &:hover {
        box-shadow: none;
      }

      &:active {
        color: $black !important;
      }

      &:focus {
        border: none;
        border-color: transparent !important;
        color: $black !important;
      }
    }
  }

  &.black,
  &.green {
    &:focus {
      color: $white;
      padding: $spacer2x $spacer8x;
      position: relative;
    }

    &:hover {
      border-color: transparent;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
      color: white;
    }

    &.pressed,
    &:active {
      box-shadow: none;
      color: $white;
      position: relative;
    }
  }

  &.black {
    background-color: $black;
    border: 1px solid $black;
    color: $white;

    &:focus {
      border: 1px solid $gray130;
    }

    &:hover {
      background-color: $gray130;
    }

    &.pressed,
    &:active {
      background-color: $black;
      border-color: $black !important;
    }
  }

  &.green {
    background-color: $givingGreen;
    border: 1px solid $givingGreen;
    color: $white;

    &:focus {
      border: 1px solid $givingGreen;
    }

    &:hover {
      background-color: $givingGreenHover;
    }

    &.pressed,
    &:active {
      background-color: $givingGreenPressed;
      border-color: $givingGreenPressed !important;
    }
  }

  &.full-width {
    width: 100%;
  }
}

.size-medium {
  padding: $spacer2x $spacer8x !important;
}
